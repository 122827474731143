import { template as template_36fd05d562904b839e2b382b0be1c0aa } from "@ember/template-compiler";
import Component from "@glimmer/component";
import AboutPageUser from "discourse/components/about-page-user";
export default class LegacyAboutPageUsers extends Component {
    get users() {
        return this.args.users || [];
    }
    static{
        template_36fd05d562904b839e2b382b0be1c0aa(`
    {{#each this.users as |user|}}
      <AboutPageUser @user={{user}} />
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
